const translations = {
  router: {
    home: 'Home',
    about_us: 'Over ons',
    product: 'Product',
    pricing: 'Prijzen',
    faq: 'FAQ',
    contact: 'Contact',
    login: 'login',
    cookie_statement: 'Cookie statement',
    privacy_policy: 'Privacy policy',
    terms_of_service: 'Terms of service',
    demo: 'Probeer de demo'
  },
  credits: 'Inc. Alle rechten voorbehouden.',
  form: {
    input: {
      name: 'Naam',
      email: 'Email',
      company: 'Organisatie',
      message: 'Bericht',
    },
    placeholder: {
      name: 'Hoe kunnen we u aanspreken?',
      email: 'How kunnen we u bereiken?',
      company: 'Wat is de naam van uw bedrijf of organisatie?',
      message: 'Waar kunnen we u mee helpen?',
    },
    consent: {
      one: 'Door verzending, gaat u akkoord met onze',
      two: 'Privacy Policy',
      three: 'en',
      four: 'Cookie statement',
    }
  },
  button: {
    lets_talk: 'Lets Talk',
    contact_us: 'Neem contact op',
    learn_more: 'Meer informatie',
    contact: 'Contact',
    get_started: 'Aan de slag',
  },
  page: {
    home: {
      header: {
        title: {
          one: 'Objectieve sollicitatietool voor',
          two: 'uw bedrijf'
        },
        description: 'Beyond Selection is een innovatieve sollicitatietool om een sterke en langdurige arbeidsrelatie te creëren tussen uw bedrijf en haar mensen, op een zo’n effectief en eerlijk mogelijke manier.'
      },
      cta: {
        title: 'Bedrijven lopen uitstekend talent mis',
        body: {
          first: 'Leeftijd, Geslacht, Afkomst, Religie en Handicap spelen een aanzienlijke rol bij het selecteren van nieuwe medewerkers. Dit is grotendeels gebaseerd op (onbewuste) arbeidsdiscriminatie.',
          second: 'Beyond Selection pakt dit probleem aan met zijn innovatieve sollicitatietool waarmee uw bedrijf inclusiever, meer geëngageerd en agile wordt. Een divers team is beter in staat om uitdagingen aan te gaan en uw bedrijf succesvoller te maken.',
          third: 'Ons doel is een toekomst waarin een objectief sollicitatieproces geen uitzondering is maar de norm. Met behulp van ons innovatieve software platform stroomlijnen wij niet alleen uw gehele werving- en selectieproces maar komen we ook dichterbij ons doel.'
        }
      },
      usp: {
        title: 'Een betere manier van solliciteren',
        one: {
          name: 'Effectiviteit',
          description: 'Organisaties moeten zich kunnen focussen op het vinden van de beste medewerkers en het creëren van een sterke en langdurige arbeidsrelatie in plaats van ondergedompeld te raken in het regelwerk hieromheen. Een overzichtelijk dashboard maakt het mogelijk om het proces eenvoudig te volgen, screenen, beoordelen en nog veel meer. Van nu af aan is het vinden van de juiste kandidaat vele malen gemakkelijker en sneller.'
        },
        two: {
          name: 'Gelijkheid',
          description: 'Arbeidsdiscriminatie is helaas nog altijd een probleem. Door het anonimiseren van het CV en andere informatie bij de eerste sollicitatiestap kunnen we samen deze vaak onbewuste arbeidsdiscriminatie tegen gaan. Sollicitanten zullen in eerste instantie anoniem beoordeeld worden op motivatie, werkervaring en opleiding. Pas als de beoordelaar(s) de sollicitant als een serieuze kandidaat beschouwen zal er meer informatie over de sollicitant bekend worden.'
        },
        three: {
          name: 'Transparantie',
          description: 'Het sollicitatieproces is transparant. Sollicitanten zullen zien welke persoonlijke informatie wanneer wordt gedeeld met het bedrijf. Het bedrijf kan ook instellen om andere informatie automatisch te delen, zodat de kandidaat gemakkelijk op de hoogte kan worden gehouden over het sollicitatieproces.'
        },
        four: {
          name: 'Aanpasbaar',
          description: 'U krijgt uw eigen, aanpasbare dashboard met uw eigen logo’s en branding. Zowel voor u als voor de sollicitant zal het voelen als een bekende omgeving. Daarnaast kunt u instellen dat alle (automatische) communicatie vanuit uw persoonlijke bedrijfs-emailadres wordt verstuurd.'
        },
      },
      logo_cloud: {
        title: 'Deze bedrijven zijn trotse supporters van Beyond Selection'
      },
      contact: {
        title: 'contact',
        description: 'Neem contact met ons op'
      }
    },
    about_us: {
      header: {
        title: 'Over ons',
        description: 'Wij geloven in eerlijke kansen.'
      },
      content: {
        title: 'Beyond Software',
        body: {
          one: 'Beyond Selection is opgezet door jonge, enthousiaste ondernemers. Door onze werkervaring, onze westerse achtergrond in combinatie met de multiculturele wijken waarin wij gewoond hebben en de huidige maatschappelijke ontwikkelingen zijn wij ons gaan realiseren dat inclusiviteit bij het sollicitatieproces nog verre van perfect is. Hierom hebben wij een concreet idee bedacht om een software tool te creëren waarbij sollicitanten anoniem solliciteren.',
          two: 'Ons product past anonimisatie toe op het CV en informatie. Hierdoor zullen sollicitanten anoniem beoordeeld worden op motivatie, werkervaring en opleiding. Pas als de beoordelaar(s) de sollicitant als een serieuze kandidaat beschouwen zal er meer informatie bekend worden.' +
              'We hopen een wereld te creëren waarin:',
          three: {
            one: 'Elke sollicitant een eerlijke kans heeft.',
            two: 'De werkgever en werknemer een sterke en langdurige arbeidsrelatie genieten.'
          },
          four: 'Het doel van ons bedrijf gaat verder dan het ontwikkelen van software waarmee we uw bedrijf naar het volgende level kunnen tillen. Wij vechten voor een toekomst waarin eerlijke kansen op de arbeidsmarkt geen uitzondering zijn maar de norm.',
          five: 'Wij zullen ons blijven focussen op het ontwerpen van oplossingen die een positieve maatschappelijke bijdrage hebben en bijdragen aan vitale bedrijfsprocessen. Samen halen we het beste uit uw bedrijf en veranderen we de wereld van het solliciteren.',
        }
      }
    },
    product: {
      header: {
        title: 'Het product',
        description: 'Met gepaste trots delen wij graag met u wat ons product te bieden heeft. '
      },
      general_description: 'Beyond Selection biedt u een professionele sollicitatietool bestaande uit een overzichtelijk dashboard en sollicitatieportaal. Beide zijn aanpasbaar met uw eigen logo en branding. Vanuit het dashboard doorloopt u gemakkelijk alle stappen van het werving- en selectieproces. ',
      vacancies: {
        title: 'Vacatures',
        description: 'U maakt eenvoudig een vacature aan. Aangemaakte vacatures zijn terug te vinden op uw dashboard. U ziet in een oogopslag welke openstaande vacatures u heeft, hoeveel kandidaten gesolliciteerd hebben en wanneer de vacature sluit.',
        one: {
          title: 'Open Sollicitaties',
          description: 'Onze sollicitatietool biedt naast vacatures voor een specifieke functie ook de mogelijkheid om open sollicitaties te ontvangen.'
        },
        two: {
          title: 'Conceptversie',
          description: 'Bij het aanmaken van de vacature kunt u een conceptversie opslaan. De vacature kan dan bijvoorbeeld nog door u of uw collega aangepast worden voordat u deze publiceert.'
        },
        three: {
          title: 'Intern of Extern',
          description: 'Bij het publiceren van de vacature kunt u ervoor kiezen om deze intern of extern te publiceren. Bij een interne vacature kunnen kandidaten enkel solliciteren met een specifieke link, bij extern solliciteren kunnen ook andere kandidaten de vacature zien.'
        },
        four: {
          title: 'Portaal',
          description: 'Potentiële kandidaten kunnen solliciteren op het hiervoor speciaal ontworpen sollicitatieportaal. In dit portaal kunnen zij hun CV, motivatie en andere gegevens uploaden.'
        }
      },
      assessment: {
        title: 'Beoordelen',
        description: 'Op uw dashboard ziet u een overzicht van de kandidaten die gesolliciteerd hebben. Gegevens over de sollicitant zijn in eerste instantie geanonimiseerd voor u beschikbaar. Middels een rating kunt u de kandidaat beoordelen. Pas als u de sollicitant als een serieuze kandidaat beschouwd zal er meer informatie bekend worden. Waarna u verdere beoordelingsmomenten kunt inplannen.',
        one: {
          title: 'Rating',
          description: 'U kunt sollicitanten gemakkelijk een rating geven gebaseerd op motivatie, werkervaring en opleiding. Een gemiddelde rating per kandidaat is te zien op de overzichtspagina, waarbij kandidaten met de hoogste rating bovenaan worden geplaatst. U kunt op basis van deze rating bepalen welke kandidaten het meest geschikt zijn voor de functie. '
        },
        two: {
          title: 'Beoordelingsmomenten',
          description: 'Verdere beoordelingsmomenten als een telefoon-, video- of face-to-face gesprek plant u eenvoudig via de tool in. U kunt hierbij meerdere data en tijdstippen voorstellen aan de sollicitant. Tevens, kan het per kandidaat wisselen welke en hoeveel beoordelingsmomenten u inplant.'
        },
        three: {
          title: 'Tijdlijn',
          description: 'Op uw dashboard ziet u per kandidaat een tijdlijn waarin alle doorgelopen stappen te zien zijn.'
        }
      },
      other_functionalities: {
        title: 'Overige functionaliteiten',
        one: {
          title: 'Opmerkingen',
          description: 'Per sollicitatiefase maakt u gemakkelijk opmerkingen. U kunt algemene opmerkingen toevoegen, maar ook bij de rating en elk volgend beoordelingsmoment is er de mogelijkheid om een opmerking te maken. Hierdoor kunt u goed bijhouden waarom een kandidaat wel of niet geschikt is voor de functie.'
        },
        two: {
          title: 'Samenwerken',
          description: 'U kunt met meerdere collega’s samenwerken binnen de sollitatietool. Zo kan het per vacature wisselen wie de vacature beheert, maar ook binnen een vacature kunnen er meerdere beoordelaars actief zijn.'
        },
        three: {
          title: 'Meertalig',
          description: 'De sollicitatietool is meertalig. De functionaliteiten en gegevens zijn in meerdere talen beschikbaar. Dit is handig voor anderstalige sollicitanten en beoordelaars.'
        },
        four: {
          title: 'AVG',
          description: 'De sollicitatietool van Beyond Selection voldoet aan alle AVG wetgeving. Wij gaan voorzichtig om met alle tot onze beschikking gestelde persoonsgegevens.'
        },
        five: {
          title: 'Mail Server',
          description: 'U kunt u eigen mailserver(SMPT) koppelen aan de sollicitatietool. Hierdoor wordt alle (automatische) communicatie verstuurd vanuit uw eigen bedrijfs-mailaccount.'
        },
      },
    },
    pricing: {
      header: {
        title: 'De Prijzen',
        description: 'Neem contact met ons op voor een pilot. Prijzen zullen binnenkort beschikbaar zijn.'
      },
    },
    faq: {
      header: {
        title: 'Veelgestelde vragen',
        description: {
          content: {
			  one: 'Kunt u het antwoord op uw vraag niet vinden? Neem',
			  two: 'met ons op.'
		  },
          action: 'contact'
        }
      },
      question: {
        one: {
          name: 'Maakt Beyond Selection gebruik van een AI/algoritme?',
          answer: 'Nee, wij gebruiken bewust geen AI of ander algoritme. Deze kunnen arbeidsdiscriminatie potentieel juist in de hand werken. Daarnaast hechten wij juist veel waarde aan het menselijke aspect van de sollicitatie en willen wij deze niet kwijt raken door een AI/algoritme te gebruiken.'
        },
        two: {
          name: 'Is er eigenlijk wel bewijs dat CV-anonimisatie arbeidsdiscriminatie tegengaat?',
          answer: 'TNO heeft in opdracht van het ministerie van sociale zaken en werkgelegenheid de Delphi studie uitgevoerd. In het kader van de pijler ‘onderzoek & instrumenten’ kwam naar voren dat CV-anonimisatie wordt ingeschat als een effectief werkzame interventie om arbeidsdiscriminatie tegen te gaan en diversiteit te vergroten. (TNO, 2020)'
        },
        three: {
          name: 'Heb ik wel genoeg informatie om een potentiële kandidaat te kunnen beoordelen?',
          answer: 'Ja, in principe heeft u genoeg informatie. Wij doen ons uiterste best om goed inzicht te geven in de motivatie, werkervaring en opleiding van een sollicitant. Daarnaast werken wij momenteel aan nieuwe manieren om inzicht te geven in bijvoorbeeld iemands persoonlijkheid om een nog beter onderbouwde keuze te maken in kandidaten.'
        },
        four: {
          name: 'Heb ik naast Beyond Selection nog mijn huidige sollicitatietool/ATS nodig?',
          answer: 'Beyond Selection biedt een breed scala aan functies voor het werving- en selectieproces. Mocht u specifieke functies missen dan kunt u altijd contact met ons opnemen. Wij zullen dan kijken in hoeverre wij hierin kunnen voorzien.'
        },
        five: {
          name: 'Hoe kunnen sollicitanten mijn vacature vinden?',
          answer: 'Uw bedrijf zal een eigen portaal hebben waarin kandidaten kunnen solliciteren voor een bepaalde vacature. Een link naar dit portaal kunt u gemakkelijk en (als u dat wilt) automatisch delen op verschillende vacaturewebsites, op platformen als LinkedIn of via E-mail.'
        },
      }
    },
    contact: {
      header: {
        title: 'Contact',
        description: 'Wij komen graag in contact met u.',
      },
      form: 'Vul hier uw contactgegevens in, wij nemen zo snel mogelijk contact met u op.'
    },
    not_found: {
      header: {
        title: 'Pagina niet gevonden',
        description: 'De pagina die u zocht is niet gevonden',
      },
      action: 'Ga terug naar de startpagina'
    }
  }


}
export default translations
