const translations = {
    router: {
        home: 'Home',
        about_us: 'About us',
        product: 'Product',
        pricing: 'Pricing',
        faq: 'FAQ',
        contact: 'Contact',
        login: 'login',
        cookie_statement: 'Cookie statement',
        privacy_policy: 'Privacy policy',
        terms_of_service: 'Terms of service',
        demo: 'Try the demo'
    },
    credits: 'Inc. All rights reserved.',
    form: {
        input: {
            name: 'Name',
            email: 'Email',
            company: 'Company',
            message: 'Message',
        },
        placeholder: {
            name: 'How can we address you?',
            email: 'How can we reach you?',
            company: 'What is your company name?',
            message: 'Where can we help you with?',
        },
        consent: {
            one: 'By sending, you agree to the',
            two: 'Privacy Policy',
            three: 'and',
            four: 'Cookie statement',
        }
    },
    button: {
        lets_talk: 'Lets Talk',
        contact_us: 'Contact us',
        learn_more: 'Learn More',
        contact: 'Contact',
        get_started: 'Get started',
    },
    page: {
        home: {
            header: {
                title: {
                    one: 'Unbiased software to empower',
                    two: 'your business'
                },
                description: 'Beyond Selection is the Applicant Tracking System that creates a strong and sustainable match between the business and their people, as objectively as possible. '
            },
            cta: {
                title: 'Companies are missing out on recruiting top talent',
                body: {
                    first: 'Age, Sex, Ethnicity, Religion and Handicap play a substantial role in selecting new hires. This is based on (unconscious) bias of employers.',
                    second: 'We provide the tools to eliminate this bias and make your company more inclusive, engaged and agile. A diverse team is known to be better equipped to deal with challenges and make your business more successful.',
                    third: 'Our goal is to make diverse hiring the standard by providing an innovative software platform that streamlines your entire hiring process. Using this tool will help you build your perfect team.'
                }
            },
            usp: {
                title: 'A better way to hire',
                one: {
                    name: 'Effective recruitment',
                    description: 'Businesses should be focusing on finding the best hires and creating a strong and sustainable match instead of being overloaded with all the paperwork. A simple dashboard will allow you to track, screen, asses and many more. From now on recruiting will be easier, faster and will find you the best hire.'
                },
                two: {
                    name: 'Equality',
                    description: 'Discrimination in the hiring process is sadly still a problem. We make sure that every applicant is unrecognizable when they enter their CV and information. All the applicants will then be screened on their Motivation, Work experience and Education. Only when the recruiter, the manager or the business owner sees the applicant as a possible hire, more information about the applicant will be unveiled.'
                },
                three: {
                    name: 'Transparent',
                    description: 'Every step of the hiring process will be transparent. Applicants can see what information will be shared with the recruiter when they become a prospect hire. Some information will never automatically be shared, only on request of the recruiter.'
                },
                four: {
                    name: 'Customizable',
                    description: 'You will have your own customizable dashboard with logo’s and branding. You, as well as your potential hire will be in a familiar environment. Additionally, it is possible to configure sending all (automatic) communication from your own company E-mail address.'
                },
            },
            logo_cloud: {
                title: 'These companies proudly support Beyond Selection'
            },
            contact: {
                title: 'contact',
                description: 'Get in touch with us.'
            }
        },
        about_us: {
            header: {
                title: 'About us',
                description: 'We believe in equal opportunities.'
            },
            content: {
                title: 'Beyond Software',
                body: {
                    one: 'Beyond selection is founded by young, enthusiastic entrepreneurs. By our working experience, our western background in combination with the multicultural neighborhoods we lived in and the current developments in society we realized inclusivity in the hiring process is far from perfect. We came up with a concrete plan to create a software tool in which applicants apply anonymously.',
                    two: 'With our product we make sure that every applicant is unrecognizable when they enter their CV and information. All the applicants will be screened on their Motivation, Work experience and Education. Only when the recruiter, the manager or the business owner sees the applicant as a possible hire, more info of the applicant will be unveiled.\n' +
                        'We\'re trying to create a world where:\n',
                    three: {
                        one: 'Every applicant gets a fair chance.',
                        two: 'Both employer and employee enjoy a sustainable match.'
                    },
                    four: 'Our efforts go beyond manufacturing software and helping your business reach the next level. We\'re fighting for a future in which equal opportunities are not an exception but the standard.',
                    five: 'We will continue to design solutions that aim to bring about a positive cultural change while aiding in vital business processes. Together we will bring out the best in your company and change the world of hiring.',
                }
            }
        },
        product: {
            header: {
                title: 'The product',
                description: 'We are proud to share what our product has to offer you.'
            },
            general_description: 'Beyond Selection offers a professional Applicant Tracking System (ATS) consisting of a simple yet extensive dashboard and a portal in which candidates can apply. Both are customizable with your own logo and branding. By use of the dashboard your entire hiring process can be easily streamlined.',
            vacancies: {
                title: 'Vacancies',
                description: 'Almost effortlessly you can create a vacancy. On the dashboard you can find an overview of your created vacancies. At a glance you get insight in your open vacancies, how many candidates applied to a vacancy and when the vacancy closes.',
                one: {
                    title: 'Open Application',
                    description: 'Besides vacancies for a specific position our ATS also offers the possibility for open application of candidates. '
                },
                two: {
                    title: 'Draft',
                    description: 'While creating a vacancy you can save a draft version. This allows you or your colleague to edit the vacancy before publishment, for example. '
                },
                three: {
                    title: 'Internal or External',
                    description: 'When publishing the vacancy you can choose to publish it internal or external. With an internal vacancy candidates can only apply by using a specific link, with an external vacancy other candidates can see the vacancy as well. '
                },
                four: {
                    title: 'Portal',
                    description: 'Potential candidates can apply for a vacancy via a portal, specifically designed for this purpose. In this portal they can upload their CV, motivation and other information.'
                }
            },
            assessment: {
                title: 'Assessment',
                description: 'On the dashboard you have an overview of the applicants. Here you can see anonymized information about an applicant. By use of a rating you can asses the candidate. Only when the applicant is considered as a possible hire, more information will be unveiled. Subsequently you can schedule further assessments.',
                one: {
                    title: 'Rating',
                    description: 'You can easily give candidates a rating based on their motivation, working experience and education. An average rating per candidate is visible in the applicant overview. Candidates with higher ratings will be on top of the applicant list. This allows you to select the best potential hire for a position.'
                },
                two: {
                    title: 'Assessment',
                    description: 'Further assessment such as a phone-, video- or face-to-face assessment can be scheduled from your dashboard. You can offer multiple possibilities in dates and times to your applicant. Furthermore, you can freely choose which and how many assessments you schedule per applicant.'
                },
                three: {
                    title: 'Timeline',
                    description: 'On your dashboard you will have a timeline in which you have an overview of the process. '
                }
            },
            other_functionalities: {
                title: 'Other functionality',
                one: {
                    title: 'Comments',
                    description: 'You can add comments during the entire hiring process. U can add general comments, but you can also make comments on the rating and every following assessment. By use of these comments you can keep track of whether a candidate is suited for the position.'
                },
                two: {
                    title: 'Work Together',
                    description: 'Within our ATS you can work together with multiple colleagues. Different vacancies can be managed by different people, and within a vacancy multiple persons can assess the potential candidates.'
                },
                three: {
                    title: 'Multi-language',
                    description: 'The ATS is multi-language. Functionalities and information of applicants will be available in multiple languages. This is useful for applicants and assessors that have a foreign mother tongue.'
                },
                four: {
                    title: 'GDPR',
                    description: 'The ATS of Beyond Selection meets the criteria for the GDPR legislation. We are very careful with the personal data we have access to.'
                },
                five: {
                    title: 'Mail Server',
                    description: 'You can configure the ATS with your own mail server. This allows for sending all (automatic) communication from your own company E-mail address. '
                },
            },
        },
        pricing: {
            header: {
                title: 'The Pricing',
                description: 'Get in touch with us for a pilot. Pricing will be available soon.'
            },
        },
        faq: {
            header: {
                title: 'Frequently asked questions',
                description: {
					content: {
					  one: 'Can’t find the answer you’re looking for? Reach out to our ',
					  two: ''
					},
                    action: 'customer support.'
                }
            },
            question: {
                one: {
                    name: 'Are you using AI/algorithms?',
                    answer: 'No, we’re not using an AI or other algorithms. These can be biased too, furthermore we don’t want to lose the human aspect of the hiring process.'
                },
                two: {
                    name: 'Is there any evidence that proves that an anonymous cv actually improves the objectiveness of hiring?',
                    answer: 'Commissioned by the dutch ministry of social affairs and employment opportunities TNO conducted the Delphi study. In context of the research pillar ‘Research & instruments’ CV-anonymization emerged as an effective intervention to reduce employment discrimination and to increase diversity. (TNO, 2020)'
                },
                three: {
                    name: 'Will I have enough information to get a good overview of an applicant?',
                    answer: 'Yes we think you do, we try our utmost best to give you a good insight in an applicant’s motivation, working experience and education. Currently we are also working on new ways to give insights in for example a person’s personality to enable better substantiated choices.'
                },
                four: {
                    name: 'Do I still need my current applicant tracking system or will beyond selection be sufficient?',
                    answer: 'Beyond selection offers a broad range of functions within the hiring process. If you have specific missing needs you can get into contact with us and we can discuss to what extend we can provide in those needs.'
                },
                five: {
                    name: 'How do applicants know how to find my vacancies?',
                    answer: 'Your company will have a specific portal for applicants where they can apply for a vacancy. A link to this page will be available for you and can be easily and (if wanted) automatically shared on multiple job boards, platforms such as LinkedIn or via E-mail.'
                },
            }
        },
        contact: {
            header: {
                title: 'Contact',
                description: 'We are happy to get into contact with you.',
            },
            form: 'Please leave your contact details, we\'ll get in touch with you as soon as possible.'
        },
        not_found: {
            header: {
                title: 'Page Not Found',
                description: 'The page you where looking for is not found',
            },
            action: 'Go back to the home page'
        }
    }


}
export default translations
