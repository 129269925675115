<template>
  <div class="flex space-x-4 px-4">
    <div class="flex cursor-pointer" v-for="locale in locales" :key="locale" @click="lang(locale)">
      <Icon class="w-8 h-6" :name="locale"/>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
export default {
  name: "Locales",
  components: {
    Icon
  },
  data() {
    return {
      locales: ['nl', 'en'],
    }
  },
  methods: {
    lang(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        // this.$inertia.get(route('language', locale));
      }
    }
  }
}
</script>
