module.exports = [
{
  path: '/',
  name: 'Home',
  meta: {
    title: 'Beyond Selection - Home',
    name: 'Home',
    metaTags: [
      {
        name: 'description',
        content: "Unbiased software to empower your business"
      },
      {
        property: 'og:description',
        content: 'Unbiased software to empower your business'
      }
    ]
  },
  component: () => import('../views/Home.vue')
},
{
  path: '/product',
  name: 'Product',
  meta: {
    title: 'Beyond Selection - Product',
    name: 'Product',
    metaTags: [
      {
        name: 'description',
                content: "Unbiased software to empower your business"
              },
              {
                property: 'og:description',
                content: 'Unbiased software to empower your business'
              }
            ]
          },
          component: () => import('../views/Product.vue')
        },
        {
          path: '/pricing',
          name: 'Pricing',
          meta: {
            title: 'Beyond Selection - Pricing',
            name: 'Pricing',
            metaTags: [
              {
                name: 'description',
                content: "Unbiased software to empower your business"
              },
              {
                property: 'og:description',
                content: 'Unbiased software to empower your business'
              }
            ]
          },
          component: () => import('../views/Pricing.vue')
        },
        {
          path: '/faq',
          name: 'FAQ',
          meta: {
            title: 'Beyond Selection - FAQ',
            name: 'FAQ',
            metaTags: [
              {
                name: 'description',
                content: "Unbiased software to empower your business"
              },
              {
                property: 'og:description',
                content: 'Unbiased software to empower your business'
              }
            ]
          },
          component: () => import('../views/FAQ.vue')
        },
        {
          path: '/about',
          name: 'AboutUs',
          meta: {
            title: 'Beyond Selection - AboutUs',
            name: 'AboutUs',
            metaTags: [
              {
                name: 'description',
                content: "Unbiased software to empower your business"
              },
              {
                property: 'og:description',
                content: 'Unbiased software to empower your business'
              }
            ]
          },
          component: () => import('../views/AboutUs.vue')
        },
        {
          path: '/contact',
          name: 'Contact',
          meta: {
            title: 'Beyond Selection - Contact',
            name: 'Contact',
            metaTags: [
              {
                name: 'description',
                content: "Unbiased software to empower your business"
              },
              {
                property: 'og:description',
                content: 'Unbiased software to empower your business'
              }
            ]
          },
          component: () => import('../views/Contact.vue')
        },
        {
          path: '/cookie-statment',
          name: 'CookieStatment',
          component: () => import('../views/hidden/CookieStatment.vue')
        },
        { path: "/:catchAll(.*)",
          component: () => import('../views/hidden/PageNotFound.vue')
        }
      ]

