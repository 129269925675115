<template>
    <div>
        <div v-if="name === 'en'">
            <svg class="w-8 h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 152 102"
                 fill="#fff" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                <use xlink:href="#EN" x=".5" y=".5"/>
                <symbol id="EN" overflow="visible">
                    <g stroke="none">
                        <path fill="#00247d" d="M0 0h150v100H0z"/>
                        <path
                            d="M58.25 100V72.337L16.813 100H0V88.833l33.25-22.167H0V33.333h33.25L0 11.167V0h16.813L58.25 27.663V0h33.5v27.663L133.188 0H150v11.167l-33.25 22.167H150v33.333h-33.25L150 88.833V100h-16.812L91.75 72.337V100h-33.5z"/>
                        <path d="M85 40h65v20H85v40H65V60H0V40h65V0h20zM0 0l50 33.333H38.75L0 7.5z" fill="#cf142b"/>
                    </g>
                    <path d="M150 100l-50-33.333h11.25L150 92.5z" fill="#cf142b" stroke="none"/>
                    <path d="M91.75 33.333v-2L138.75 0H150l-50 33.333zm-33.5 33.334v2L11.25 100H0l50-33.333z"
                          stroke="none" fill="#cf142b"/>
                </symbol>
            </svg>
        </div>
        <div v-if="name === 'nl'">
            <svg class="w-8 h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 viewBox="0 0 151 101" fill="#fff" fill-rule="evenodd" stroke="#000" stroke-linecap="round"
                 stroke-linejoin="round">
                <use xlink:href="#NL" x=".5" y=".5"/>
                <symbol id="NL" overflow="visible">
                    <g stroke="none">
                        <path d="M0 33.333h150V0H0z" fill="#ae1c28"/>
                        <path d="M0 33.333h150v33.333H0z"/>
                        <path d="M0 66.667h150V100H0z" fill="#21468b"/>
                    </g>
                </symbol>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['name'],
        name: "icon"
    }
</script>
