<template>
  <footer class="bg-indigo-700 z-30 relative">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
        <div v-for="item in main" :key="item.name" class="px-5 py-2">
          <router-link  :to="item.href" class="text-base text-white hover:text-gray-500">
            {{ item.name }}
          </router-link>
        </div>
        <div class="px-5 py-2">
          <a href="./privacy-policy.pdf" target="_blank" class="text-base text-white hover:text-gray-500">
            {{ $t('router.privacy_policy') }}
          </a>
        </div>
        <div class="px-5 py-2">
          <a href="./tos.pdf" target="_blank" class="text-base text-white hover:text-gray-500">
            {{ $t('router.terms_of_service') }}
          </a>
        </div>
      </nav>
      <div class="mt-8 flex justify-center space-x-6">
        <a v-for="item in navigation.social" target="_blank" :key="item.name" rel="noreferrer" :href="item.href" class="text-white hover:text-gray-400">
          <span class="sr-only">{{ item.name }}</span>
          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
        </a>
      </div>
      <p class="mt-8 text-center text-base text-white">
        &copy; 2020 Beyond Selection, {{ $t('credits') }}
      </p>
    </div>
  </footer>
</template>

<script>
import { defineComponent, h } from 'vue'

const navigation = {
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/beyond-selection-nl/',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d:
                    'M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
    {
      name: 'Mail',
      href: 'mailTo:info@beyond-selection.com',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d:
                    'M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
  ],
}

export default {
  setup() {
    return {
      navigation,
    }
  },
  computed: {
    main() {
      return [
        {name: this.$t('router.home'), href: '/'},
        {name: this.$t('router.contact'), href: 'contact'},
        {name: this.$t('router.cookie_statement'), href: 'cookie-statement'},
      ];
    }
  }
}
</script>
