<template>
  <nav
      class="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6"
  >
    <div class="flex items-center flex-1">
      <div class="flex items-center justify-between w-full md:w-auto">
        <router-link to="/" aria-label="Home">
          <img class="h-8 w-auto sm:h-16" :src="require(`@/assets/img/logo-min.png`)" alt="Logo"/>
        </router-link>
        <div class="-mr-2 flex items-center md:hidden">
          <button
              type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              id="main-menu"
              @click="toggleNavMobile = !toggleNavMobile"
              aria-label="Main menu"
              aria-haspopup="true"
          >
            <svg
                class="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="hidden md:block md:ml-10">
        <router-link to="/" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">
          {{ $t('router.home') }}
        </router-link>
        <router-link to="/about"
                     class="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >{{ $t('router.about_us') }}
        </router-link>
        <router-link to="/product"
                     class="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >{{ $t('router.product') }}
        </router-link>
        <router-link to="/pricing"
                     class="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >{{ $t('router.pricing') }}
        </router-link>
        <router-link to="/faq"
                     class="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >{{ $t('router.faq') }}
        </router-link>
        <router-link to="/contact"
                     class="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >{{ $t('router.contact') }}
        </router-link>
      </div>
    </div>
    <div class="hidden md:block text-right">
      <div class="inline-block align-middle">
<!--        <jet-dropdown align="right">-->
<!--          <template #trigger>-->
<!--            <button-->
<!--                type="button"-->
<!--                class="group w-full rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-tawny-port-500 bg-white border-2 border-tawny-port-500 hover:text-tawny-port-600 focus:outline-none focus:border-tawny-port-600 active:text-tawny-port-600 transition ease-in-out duration-150"-->
<!--                id="options-menu"-->
<!--                aria-haspopup="true"-->
<!--                aria-expanded="true"-->
<!--            >-->
<!--              <div class="flex w-full justify-between items-center">-->
<!--                <div-->
<!--                    class="flex min-w-0 items-center justify-between space-x-3"-->
<!--                >-->
<!--                  <div class="flex-1 min-w-0">-->
<!--                    <h2-->
<!--                        class="text-sm leading-5 font-medium truncate"-->
<!--                    >-->
<!--                      Language-->
<!--                    </h2>-->
<!--                    <p class="text-gray-500 text-sm leading-5 truncate">-->

<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; Heroicon name: selector &ndash;&gt;-->
<!--                <svg-->
<!--                    class="flex-shrink-0 h-5 w-5 text-tawny-port-500 group-hover:text-tawny-port-600"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                    viewBox="0 0 20 20"-->
<!--                    fill="currentColor"-->
<!--                >-->
<!--                  <path-->
<!--                      fill-rule="evenodd"-->
<!--                      d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"-->
<!--                      clip-rule="evenodd"-->
<!--                  />-->
<!--                </svg>-->
<!--              </div>-->
<!--            </button>-->
<!--          </template>-->


<!--          <template #content>-->
<!--            &lt;!&ndash;                              lang selector&ndash;&gt;-->
<!--            <div class="block px-4 py-2 text-xs text-gray-400">-->
<!--              Preferred Language-->
<!--            </div>-->

<!--            <locales></locales>-->


<!--          </template>-->
<!--        </jet-dropdown>-->
        <div class="m-2">
          <locales></locales>
        </div>
      </div>
        <span class="inline-flex rounded-md shadow-xs">
          <a
              href="https://demo.app.beyond-selection.com/login?email=test@test.com&password=secret123"
              target="_blank"
              class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-tawny-port-500 hover:bg-tawny-port-600 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out"
          >
            {{ $t('router.demo') }}
          </a>
        </span>
    </div>
  </nav>

  <div
      v-if="toggleNavMobile"
      class="absolute z-40 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
  >
    <div class="rounded-lg shadow-md">
      <div
          class="rounded-lg bg-white shadow-xs overflow-hidden"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="main-menu"
      >
        <div class="px-5 pt-4 flex items-center justify-between">
          <div>
            <img class="h-8 w-auto" src="assets/img/logo-min.png" alt=""/>
          </div>
          <div class="-mr-2">
            <button
                @click="toggleNavMobile = !toggleNavMobile"
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                aria-label="Close menu"
            >
              <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="px-2 pt-2 pb-3">
          <router-link to="/"
                       @click="toggleNavMobile = !toggleNavMobile"
                       class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                       role="menuitem"
          >{{ $t('router.home') }}
          </router-link>
          <!--<a
            [routerLink]=[pricing]
            (click)='mobile = false'
            class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
            role="menuitem"
            >Pricing</a
          >
          <a
            [routerLink]=[faq]
            (click)='mobile = false'
            class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
            role="menuitem"
            >FAQ</a
          >-->
          <router-link to="/about"
                       @click="toggleNavMobile = !toggleNavMobile"
                       class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                       role="menuitem"
          >{{ $t('router.about_us') }}
          </router-link>
          <router-link to="/product"
                       @click="toggleNavMobile = !toggleNavMobile"
                       class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                       role="menuitem"
          >{{ $t('router.product') }}
          </router-link>
          <router-link to="/pricing"
                       @click="toggleNavMobile = !toggleNavMobile"
                       class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                       role="menuitem"
          >{{ $t('router.pricing') }}
          </router-link>
          <router-link to="/faq"
                       @click="toggleNavMobile = !toggleNavMobile"
                       class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                       role="menuitem"
          >{{ $t('router.faq') }}
          </router-link>
          <router-link to="/contact"
                       @click="toggleNavMobile = !toggleNavMobile"
                       class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                       role="menuitem"
          >{{ $t('router.contact') }}
          </router-link>
        </div>
        <div class="pt-4 pb-2 bg-gray-50 rounded-lg">
          <div class="items-center px-2">
            <locales class="mt-3 px-2"></locales>

            <a href="https://demo.app.beyond-selection.com/login?email=test@test.com&password=secret123"
               target="_blank"
               @click="toggleNavMobile = !toggleNavMobile"
               class="block w-full px-5 py-3 text-center font-medium text-tawny-port-500 bg-gray-50 hover:bg-gray-100 hover:text-tawny-port-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"
               role="menuitem"
            >
              {{ $t('router.demo') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Locales from "@/components/Locales";

export default {
  name: 'Navbar',
  data() {
    return {
      toggleNavMobile: false,
    };
  },
  components: {
    Locales
  }
}
</script>
