// import Api from '../api/api.js'

let actions = {

    // setSelectedPrograms({commit}, payload) {
    //     commit("SET_SELECTED_PROGRAMS", payload);
    // },
};

export default actions
