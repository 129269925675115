import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
const routes = require('./routes.js')

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

trackRouter(router);

export default { router, routes }
