import {createApp} from 'vue'
import App from './App.vue'
import { router } from './router/index'
import store from './store'
import './assets/css/tailwind.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import VueGtag from "vue-gtag-next";

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    if (!nearestWithMeta) return next();
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        .forEach(tag => document.head.appendChild(tag));

    next();
});


createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(VueGtag,{
        property: {
            id: "G-YKHB3XQX4L"
        }
    })
    .mount('#app')

